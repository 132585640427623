@import 'assets/styles/colours.scss';

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~nvd3/build/nv.d3.min.css";


/*@import "assets/libs/font-awesome/css/font-awesome.min.css";
@import "assets/libs/textAngular/textAngular.css";*/
@import "~font-awesome/css/font-awesome.css";

@import "./assets/css/ng-bootstrap-to-bootstrap-3.min.css";
@import "./assets/css/core.scss";
/*@import "~assets/libs/ng-table/ng-table.css";*/


/*BOOTSREAP CALENDER HACK - REMOVE ME SOON*/
.ngb-dp-day .btn-light.bg-primary.text-white {
    background-color: #337ab7!important;
}

.mat-tooltip.searchResultTooltip {
    font-size: 18px;
    max-width: 700px !important;
}

.mat-tooltip.engagement-title-tooltip {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.mat-tooltip.approval-reason-tooltip {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    white-space: pre-line;
}

/* a few overrides for material */
.mat-checkbox label, .mat-radio-label {
    font-weight: normal;
}
.mat-form-field-suffix .mat-icon-button .mat-icon {
    width: 1.5em;
    font-size: 1.5em;
    line-height: 1.125;
}
.mat-menu-panel {
    border-radius: 10px !important;
}
.mat-drawer-container {
    background-color: #ebeaea !important;
    color: inherit !important;
}
.notif-success {
    color: #fff !important;
    background-color: #4aaa4c !important;
    border: none !important;
}
.notif-success button{
    color: #fff !important;
}

.mat-snack-bar-container {
    background-color: $tsb-blue !important;
    color: $tsb-aquamarine !important;
}

.mat-simple-snackbar {
    font-size: 20px !important;
    line-height: 30px !important;
    padding: 20px, 10px 20px 10px;
}

.dialogue-container {
    border-radius: 10px;
    overflow: hidden;
}

.quick-find-modal mat-dialog-container {
    padding: 0 !important;
    border-radius: 10px;
}

.new-feature-backdrop-background {
    background-color: #0000001A;
    backdrop-filter: blur(2px);
}

@media (max-width: 1200px) {
    .quick-find-modal {
        width: 60% !important;
        height: 80% !important;
    }
}
.mat-button,
.mat-raised-button,
.mat-panel-title,
.mat-panel-header,
.mat-expansion-panel-header,
.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title,
.mat-expansion-panel-content,
.mat-expansion-panel-header-description,
.mat-select-value,
.mat-form-field,
.mat-checkbox-label,
.mat-tab-group,
.mat-primary,
.mat-tab-label,
.mat-tab-link,
.mat-card,
.mat-radio-button,
.mat-dialog-title
{
    font-family: Poppins, sans-serif !important;
}

.mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
   color: #fff !important;
}

.edit-featured-response {
    border: 1px solid $tsb-aquamarine;
}

body {
    overflow-y: hidden;
}

/* SB - CORE */

/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}





/* Custom schoolbus font styles*/

@font-face {
    font-family: 'core';
    src: url('../fonts/core-webfont.woff2') format('woff2'), url('../fonts/core-webfont.woff') format('woff'), url("../fonts/core-webfont.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

.sbicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'core';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sbicon.sbicon-calendar:before {  content: "0";}
.sbicon.sbicon-forum:before {  content: "1";}
.sbicon.sbicon-policy:before {  content: "A";}
.sbicon.sbicon-template:before {  content: "B";}
.sbicon.sbicon-article:before {  content: "C";}
.sbicon.sbicon-3mr:before {  content: "D";}
.sbicon.sbicon-qa:before {  content: "E";}
.sbicon.sbicon-guidance:before {  content: "F";}
.sbicon.sbicon-tool:before {  content: "G";}



::-moz-selection { /* Code for Firefox */
    background-color: #ffd132;
    color: #347186;
    text-shadow: none;
}

::selection {
    background-color: #d6410a;
    color: #ffffff;
    text-shadow: none;
}

html, body {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #686464 /*#7c7777*/;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: #ebeaea;
}

.wrapper  {
    min-height: 100%;
    
    position:relative;
}

@media (max-width: 767px) {
    .wrapper  {
        padding-bottom:0;
    }   
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

h1 {
    font-size: 2em;
    font-weight: bold;
}

h2 {
    font-size: 1.5em;
    font-weight: bold;
}

h3 {
    font-size: 1.4em;
    font-weight: bold;
}

h4 {
    font-size: 1.3em;
    font-weight: bold;
}

h5 {
    font-size: 1.2em;
    font-weight: bold;
}

h6 {
    font-size: 1.1em;
    font-weight: bold;
}

.headerunderline {
    color: #041e42;
    border-bottom: 1px solid #d6410a;
    margin-bottom: 8px;
    padding: 10px 0px 5px 5px;
    font-weight: 400;
}

a, a:hover, a:active, a:focus {
    text-decoration: none;
}

.fnt-midblue {
    color: #347186;
}

.fnt-yellow {
    color: #ffd132;
}

.bg-lightblue1 {
    background-color: #c7d3de;
}

.bg-lightblue2 {
    background-color: #b1c9d4;
}

.bg-lightblue3 {
    background-color: #97b4c5;
}

.bg-midblue {
    background-color: #347186;
    color:#cbdddc;
}

.bg-midblue .highlight {
    color: #ffd132; /*yellow highlight*/
}

.bg-darkblue {
    background-color: #004251;
    color :#cbdddc;
}

.bg-darkblue .highlight {
    color: #ffd132; /*yellow highlight*/
}

.bg-yellow {
    background-color: #ffd132;
    color:black;
}

.btn-yellow {
    color: #686464;
    background-color: #ffd132;
    border-color: #ffcb19;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    text-shadow: none;
    vertical-align: bottom;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 4px !important;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
}

.btn-yellow:hover, .btn-warning:focus, .btn-warning:active {
    color: #fff;
    background-color: #fec500;
    border-color: #daa900;
}

/* OLD - padding for header */
/*section:first-of-type {
    margin-top: 110px;
}*/

/*aside:first-of-type {
    margin-top: 110px;
}*/

/*article:first-of-type {
    margin-top: 110px;
}*/

/*@media (max-width: 991px) {
    aside:first-of-type {
        margin-top: 0;
    }
}*/


/* NEW - PADDING FOR PAGES */
/*.page-container {
    margin-top: 182px;
}*/

.page-container-short-header {
    margin-top: 40px;
    margin-bottom: 40px;
}

.page-container-short-header-2 {
    margin-top: 40px;
    margin-bottom: 40px;
}

/*@media (max-width: 767px) {
    .page-container {
        margin-top: 87px;
    }
}*/

.page-container-header-v3 {
    margin-top: 60px;
}


.margin-top-bottom-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-top-bottom-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-top-25 {
    margin-top: 25px;
}



.search-result {
    background-color:white; 
    margin-top:10px;
    padding:20px;
}


.article-border {
    border-left: 2px solid #3b78ad;
}

.article-border h2 {
    color: #3b78ad;
    font-size: 1.3em;
}

.article-border span {
    color: #3b78ad;
}

.icon-article {
    color: #3b78ad;
}

.article-border span a {
    color: #686464;
}

.topic-border {
    border-left: 2px solid #548561;
}

.topic-border h2 {
    color: #548561;
    font-size: 1.3em;
}

.topic-border span {
    color: #548561;
}

.news-border {
    border-left: 2px solid #ad3b4d;
}

.news-border h2 {
    color: #ad3b4d;
    font-size: 1.3em;
}
.boxshadow3d {
    box-shadow:2px 2px 3px #dadada;
}

.news-border span {
    color: #ad3b4d;
}

@media (min-width: 1000px) {
    .cookie-panel-class {
        max-width: 25% !important;
    }
}

@media (max-width: 999px) {
    .cookie-panel-class {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-bottom: 0 !important;
    }
}

@media (max-width: 410px) {
    .manage-cookies-container {
        margin: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
    }
}
